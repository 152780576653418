import React from 'react';
import propTypes from 'prop-types';

import { Flex } from '@user-interviews/ui-design-system';

import * as styles from './grid_question.module.scss';

export function QuestionTitleCell({ title }) {
  return (
    <Flex
      alignItems="center"
      className={styles.QuestionTitleCell}
      container
      justifyContent="flex-start"
    >
      {title}
    </Flex>
  );
}

QuestionTitleCell.propTypes = {
  title: propTypes.string,
};

QuestionTitleCell.defaultProps = {
  title: '',
}
