import React from 'react';

import { Controller, useFormContext, useFormState } from 'react-hook-form';

import * as Constants from '../../constants';
import { surveyQuestionPropType } from '../../prop_types';

import * as styles from './grid_question.module.scss';
import { PickOneAnswerInput } from '../answer_input_components/pick_one_answer_input';

export function PickOneInputRow({ question }) {
  const { control } = useFormContext();
  const { errors } = useFormState();

  const validate = value => {
    if (question.isMandatory && value.answerId === null) {
      return Constants.REQUIRED_ERROR_MESSAGE;
    }
    return null;
  };

  const inputKey = `q${question.id}`;
  const hasError = !!errors[Constants.PICK_ONE_INPUT_GROUP_KEY]?.[inputKey];

  return (
    <Controller
      control={control}
      defaultValue={{ answerId: null }}
      name={`${Constants.PICK_ONE_INPUT_GROUP_KEY}.q${question.id}`}
      render={({ field: { onBlur, onChange, value } }) => (
        question.answers.map(answer => (
          <PickOneAnswerInput
            answer={answer}
            ariaLabel={`${question.text}-${answer.text}`}
            bordered={false}
            className={styles.InputCell}
            formValue={value}
            hasError={hasError}
            key={answer.id}
            question={question}
            value={answer.id}
            onBlur={onBlur}
            onChange={onChange}
          />
        ))
      )}
      rules={{ validate }}
    />
  );
}

PickOneInputRow.propTypes = {
  question: surveyQuestionPropType.isRequired,
};
