import { Features } from 'lib/generated_constants/features';
import { useGetFeatureFlagQuery } from 'api/feature_flag';

/*
* There are situations where we may want to set a feature flag within a controller
* instead of fetching from the api, as it gives the frontend the ability to immediately
* reason about the flag's state (potentially useful for high level conditional rendering),
* whereas fetching via the api will always have a bit of latency.
*
* To set this up in a controller add FeaturesConcern to your controller and
* call set_feature_check before rendering. This will insert it into the window
* via app/views/shared/_js_features.html.erb
*
* Note that this only supports features documented in app/services/features/flipper.rb
*
* While this does work, if you're looking for the new hotness you can add these
* flags to the RailsContext in config/initializers/react_on_rails.rb and fetch it from
* app/javascript/hooks/use_rails_context.tsx. An example of this can be found at
* https://github.com/user-interviews/rails-server/pull/27742/files
*/

const Actors = {
  Account: 'Account',
  MixpanelUser: 'MixpanelUser',
  Organization: 'Organization',
  Participant: 'Participant',
  Team: 'Team',
  User: 'User',
};

function useFeatureFlag({ featureName, actorType, sendTracking }) {
  if (!Object.values(Features).includes(featureName)) {
    throw new TypeError(`Received unexpected feature: ${featureName}`);
  }

  // When the featureName is already present in window.FEATURES, this means that
  // set_feature_check has been called in a corresponding view controller.
  // If that is the case, we can skip the redundant API request and just return that state.
  const isFeatureCheckAlreadyPresent = Object.keys(window.FEATURES || {}).includes(featureName);

  const {
    data: { isEnabled: isEnabledViaApi } = {},
    isLoading: isGetFeatureFlagLoading,
  } = useGetFeatureFlagQuery(
    { featureName, actorType, sendTracking },
    { skip: isFeatureCheckAlreadyPresent },
  );

  const isEnabled = isFeatureCheckAlreadyPresent ? window.FEATURES[featureName] : isEnabledViaApi;

  return {
    isLoading: isFeatureCheckAlreadyPresent ? false : isGetFeatureFlagLoading,
    isEnabled,
  };
}

export {
  Actors,
  Features,
  useFeatureFlag,
};
