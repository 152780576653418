import React, { useCallback, useState } from 'react';
import { Text } from '@user-interviews/ui-design-system';
import propTypes from 'prop-types';
import classNames from 'classnames';

import { useController, useFormContext } from 'react-hook-form';

import { trackingEvents } from 'lib/analytics';

import { RemoveButton } from './remove_button';
import { useAutosizeGridTextArea } from './useAutosizeGridTextArea';

import * as styles from './grid.module.scss';

export function GridQuestion({
  isEditing,
  isRemovable,
  question,
  questionGroupId,
  onChange,
  onRemove,
}) {
  const [textAreaRef, setTextAreaRef] = useState(null);

  const { formState: { errors } } = useFormContext();

  const questionName = `question.${question.uuid}`;
  const questionText = question.text || 'row';

  const { field: questionInput } = useController({
    defaultValue: question.text,
    name: questionName,
    rules: { required: true },
  });

  useAutosizeGridTextArea(textAreaRef, questionInput.value, questionGroupId);

  const hasErrors = !!errors.question?.[question.uuid];

  // Prevent a click from triggering parent event
  const handleClick = event => event.stopPropagation();

  const handleChange = useCallback(({ target: { value } }) => {
    questionInput.onChange(value);
    onChange(question.uuid, { text: value });
  }, [onChange, question.uuid, questionInput]);

  const handleRemove = useCallback(() => {
    onRemove(question.uuid);
  }, [onRemove, question.uuid]);

  if (!isEditing) {
    return (
      <Text
        className={styles.gridTitleNonEdit}
        textAlign="left"
      >
        {questionText}
      </Text>
    );
  }

  return (
    <div className={styles.gridInput}>
      <textarea
        aria-invalid={!!errors}
        aria-label="Question text"
        className={classNames(styles.gridInputTextArea, 'form-control question', { 'is-invalid': hasErrors })}
        name={questionInput.name}
        placeholder="Row value"
        ref={setTextAreaRef}
        rows={1}
        value={questionInput.value}
        onChange={handleChange}
        onClick={handleClick}
      />

      {isRemovable && (
        <RemoveButton
          ariaLabel={`Remove ${questionText}`}
          className={styles.removeButton}
          trackingEvent={trackingEvents.SURVEY_REMOVE_GRID_ROW}
          onRemove={handleRemove}
        />
      )}

    </div>
  );
}

GridQuestion.propTypes = {
  isEditing: propTypes.bool.isRequired,
  isRemovable: propTypes.bool.isRequired,
  question: propTypes.object.isRequired,
  questionGroupId: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  onRemove: propTypes.func.isRequired,
};
