import React from 'react';
import propTypes from 'prop-types';

import { SurveyBuilderConstants as Constants } from 'components/survey_builder';

import { surveyQuestionPropType } from '../../prop_types';

import { DateAnswerInput } from './date_answer_input';
import { FloatAnswerInput } from './float_answer_input';
import { TextAnswerInput } from './text_answer_input';

export function PickNoneInput({
  hasError,
  question,
  responseClass,
}) {
  switch (responseClass) {
    case Constants.RESPONSE_CLASS_DATE: {
      return (
        <DateAnswerInput
          hasError={hasError}
          question={question}
        />
      )
    }
    case Constants.RESPONSE_CLASS_FLOAT: {
      return (
        <FloatAnswerInput
          hasError={hasError}
          question={question}
        />
      )
    }
    default: {
      return (
        <TextAnswerInput
          hasError={hasError}
          question={question}
        />
      )
    }
  }
}

PickNoneInput.propTypes = {
  hasError: propTypes.bool.isRequired,
  question: surveyQuestionPropType.isRequired,
  responseClass: propTypes.string.isRequired,
};
