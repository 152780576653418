import React, { useCallback, useState } from 'react';
import { Text } from '@user-interviews/ui-design-system';
import propTypes from 'prop-types';
import classNames from 'classnames';

import { useController, useFormContext } from 'react-hook-form';

import { trackingEvents } from 'lib/analytics';

import { RemoveButton } from './remove_button';
import { useAutosizeGridTextArea } from './useAutosizeGridTextArea';

import * as styles from './grid.module.scss';

// Disabled when adding max-props rule.
// Future work should stay under 6 props
// eslint-disable-next-line local-rules/max-props
export function GridAnswer({
  answer,
  index,
  isEditing,
  isRemovable,
  questionGroupId,
  onRemove,
  onUpdateColumn,
}) {
  const [textAreaRef, setTextAreaRef] = useState(null);

  const { formState: { errors } } = useFormContext();

  const answerName = `answer.${answer.uuid}`;
  const answerText = answer.text || 'column';

  const { field: answerInput } = useController({
    defaultValue: answer.text,
    name: answerName,
    rules: { required: true },
  });

  useAutosizeGridTextArea(textAreaRef, answerInput.value, questionGroupId);

  const hasErrors = !!errors.answer?.[answer.uuid];

  // Prevent a click from triggering parent event
  const handleClick = event => event.stopPropagation();

  const handleChange = useCallback(({ target: { value } }) => {
    answerInput.onChange(value);
    onUpdateColumn(index, { text: value });
  }, [answerInput, index, onUpdateColumn]);

  const handleRemove = useCallback(() => {
    onRemove(index);
  }, [index, onRemove]);

  if (!isEditing) {
    return (
      <Text
        className={styles.gridTitleNonEdit}
        textAlign="center"
      >
        {answerText}
      </Text>
    );
  }

  return (
    <div className={styles.gridAnswer}>
      <div className={styles.gridInput}>
        <textarea
          aria-invalid={!!errors}
          aria-label="Answer text"
          className={classNames(styles.gridInputTextArea, 'form-control question', { 'is-invalid': hasErrors })}
          name={answerInput.name}
          placeholder="Column value"
          ref={setTextAreaRef}
          rows={1}
          value={answerInput.value}
          onChange={handleChange}
          onClick={handleClick}
        />

        {isRemovable && (
          <RemoveButton
            ariaLabel={`Remove ${answerText}`}
            className={styles.removeButton}
            trackingEvent={trackingEvents.SURVEY_REMOVE_GRID_COLUMN}
            onRemove={handleRemove}
          />
        )}
      </div>
    </div>
  );
}

GridAnswer.propTypes = {
  answer: propTypes.object.isRequired,
  index: propTypes.number.isRequired,
  isEditing: propTypes.bool.isRequired,
  isRemovable: propTypes.bool.isRequired,
  questionGroupId: propTypes.string.isRequired,
  onRemove: propTypes.func.isRequired,
  onUpdateColumn: propTypes.func.isRequired,
};
