import React from 'react';
import propTypes from 'prop-types';

import { Card, CardSizes } from '@user-interviews/ui-design-system';

import { QuestionEntityType, groupQuestionsByQuestionGroup } from 'lib/surveys/utils';

import { surveySectionPropType } from '../prop_types';
import { SurveyQuestionFormGroup } from './survey_question_form_group';
import { SurveySectionLoadingSkeleton } from './survey_section_loading_skeleton';
import { GridQuestionFormGroup } from './grid_question_components/grid_question_form_group';

import * as styles from './survey_section_form_card.module.css';

export function SurveySectionFormCard({
  cardSize,
  isLoading,
  surveySection,
}) {
  return (
    <Card
      className={styles.surveyFormCard}
      isLoading={isLoading}
      loadingSkeleton={<SurveySectionLoadingSkeleton />}
      size={cardSize}
    >
      {surveySection?.questions && (
        groupQuestionsByQuestionGroup(surveySection.questions).map(formQuestion => {
          if (formQuestion.type === QuestionEntityType.QUESTION_GROUP) {
            return (
              <GridQuestionFormGroup
                key={`questionGroup${formQuestion.questionGroup.id}`}
                questionGroup={formQuestion.questionGroup}
                questions={formQuestion.questions}
              />
            );
          }
          return (
            <SurveyQuestionFormGroup
              key={formQuestion.question.id}
              question={formQuestion.question}
            />
          );
        }))}
    </Card>
  );
}

SurveySectionFormCard.propTypes = {
  cardSize: propTypes.oneOf(Object.values(CardSizes)).isRequired,
  isLoading: propTypes.bool.isRequired,
  surveySection: surveySectionPropType,
};

SurveySectionFormCard.defaultProps = {
  surveySection: null,
};
