import React from 'react';
import propTypes from 'prop-types';

import { TEXT_PROPS, Text } from '@user-interviews/ui-design-system';

import { QuestionSkipLogicSummaryCondition } from './question_skip_logic_summary_condition';

import { skipLogicConditionPropType } from './prop_types';

import * as styles from './question_skip_logic_summary.module.css';

const SURVEY_END_SECTION_TITLE = 'Finish';

export function QuestionSkipLogicSummaryConditions({
  questionId,
  skipLogicJoinMethod,
  skipLogicConditions,
  survey,
  targetSectionTitle,
}) {
  const completedConditions = skipLogicConditions.filter(condition => (
    condition.answerId && condition.questionId
  ));

  if (completedConditions.length === 0) {
    return null;
  }

  const sanitizedTargetSectionTitle =
    targetSectionTitle === undefined ? SURVEY_END_SECTION_TITLE : targetSectionTitle;

  const conditionRows = completedConditions.map((condition, index) => {
    const conditionQuestion = survey.questions[condition.questionId];
    const conditionQuestionGroup = conditionQuestion?.questionGroupId && survey.questions[conditionQuestion.questionGroupId];

    return (
      <QuestionSkipLogicSummaryCondition
        conditionAnswer={survey.answers[condition.answerId]}
        conditionQuestion={conditionQuestion}
        conditionQuestionGroup={conditionQuestionGroup}
        isLastCondition={index === completedConditions.length - 1}
        key={condition.uuid}
        questionId={questionId}
        skipLogicConditionOperator={condition.operator}
        skipLogicJoinMethod={skipLogicJoinMethod}
      />
    )
  });

  return (
    <div className={styles.skipLogicSummaryLogic}>
      <Text
        className={styles.skipLogicSummaryLogicHeader}
        size={TEXT_PROPS.size.sm}
      >
        Skip to <strong>{sanitizedTargetSectionTitle}</strong> if:
      </Text>
      <ul className={styles.skipLogicSummaryLogicList}>
        {conditionRows}
      </ul>
    </div>
  );
}

QuestionSkipLogicSummaryConditions.propTypes = {
  questionId: propTypes.string.isRequired,
  skipLogicConditions: propTypes.arrayOf(skipLogicConditionPropType).isRequired,
  skipLogicJoinMethod: propTypes.string.isRequired,
  survey: propTypes.object.isRequired,
  targetSectionTitle: propTypes.string,
};

QuestionSkipLogicSummaryConditions.defaultProps = {
  targetSectionTitle: undefined,
};
