import * as BuilderConstants from 'components/survey_builder/constants';

export const PICK_ONE_INPUT_GROUP_KEY =
`${BuilderConstants.QUESTION_PICK_ONE}${BuilderConstants.RESPONSE_CLASS_ANSWER}` as const;
export const PICK_MANY_INPUT_GROUP_KEY =
`${BuilderConstants.QUESTION_PICK_ANY}${BuilderConstants.RESPONSE_CLASS_ANSWER}` as const;
export const SHORT_ANSWER_INPUT_GROUP_KEY =
    `${BuilderConstants.QUESTION_PICK_NONE}${BuilderConstants.RESPONSE_CLASS_STRING}` as const;
export const LONG_ANSWER_INPUT_GROUP_KEY =
`${BuilderConstants.QUESTION_PICK_NONE}${BuilderConstants.RESPONSE_CLASS_TEXT}` as const;
export const BOOLEAN_INPUT_GROUP_KEY =
  `${BuilderConstants.QUESTION_BOOLEAN}${BuilderConstants.RESPONSE_CLASS_BOOLEAN}` as const;
export const DATE_INPUT_GROUP_KEY =
  `${BuilderConstants.QUESTION_PICK_NONE}${BuilderConstants.RESPONSE_CLASS_DATE}` as const;
export const FLOAT_INPUT_GROUP_KEY =
  `${BuilderConstants.QUESTION_PICK_NONE}${BuilderConstants.RESPONSE_CLASS_FLOAT}` as const;
