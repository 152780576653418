import React from 'react';
import propTypes from 'prop-types';

import { Flex } from '@user-interviews/ui-design-system';

import * as styles from './grid_question.module.scss';

export function AnswerTitleCell({ title }) {
  return (
    <Flex
      alignItems="center"
      className={styles.AnswerTitleCell}
      container
      justifyContent="center"
    >
      {title}
    </Flex>
  );
}

AnswerTitleCell.propTypes = {
  title: propTypes.string,
};

AnswerTitleCell.defaultProps = {
  title: '',
}
