import React, {
  useRef,
  useState,
  type ReactNode,
} from 'react';

import { LoadingOverlay, Main } from '@user-interviews/ui-design-system';
import classNames from 'classnames';
import { Footer } from './components/footer';
import { Sidebar } from './components/sidebar';
import { SidebarContextProvider } from './components/sidebar_context_provider';

import * as styles from './page.module.scss';

type PageProps = {
  children: ReactNode,
  isCentered?: boolean,
  isFullHeight?: boolean,
  isPadded?: boolean,
}

export function Page({
  children,
  isCentered,
  isFullHeight,
  isPadded,
}: PageProps) {
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isNavigationItemTriggeringPageLoad, setIsNavigationItemTriggeringPageLoad] = useState(false);

  const handlePageLoading = () => setIsNavigationItemTriggeringPageLoad(true);

  return (
    <div
      className={classNames(
        styles.page, {
          [styles.centered]: isCentered,
          [styles.fullHeight]: isFullHeight,
          [styles.padded]: isPadded,
        },
      )}
    >
      <LoadingOverlay visible={isNavigationItemTriggeringPageLoad} />

      <SidebarContextProvider sidebarRef={sidebarRef}>
        <Sidebar ref={sidebarRef} onHandlePageLoading={handlePageLoading} />
      </SidebarContextProvider>

      <Main>
        {children}
      </Main>

      <Footer />
    </div>
  );
}
