import React, { useEffect, useState } from 'react';

import {
  CardStack, Flex, MessageTypes, Toast, useToast,
} from '@user-interviews/ui-design-system';
import { useLocation, useNavigate } from 'react-router';

import { useUpsertParticipantPopulationOptInFormParticipantMutation } from 'api/participant_population_opt_in_form_participants';
import { useLazyGetResponseSetsQuery } from 'api/response_sets';

import { ErrorRenderer } from 'lib/errors';

import { ProjectApplicationLoadingSkeleton } from 'participant/project_application/components/project_application_loading_skeleton';

import { useGetOptInForm } from '../hooks/use_get_opt_in_form';
import { useOptInFormKeyFromQueryUrl } from '../hooks/use_opt_in_form_key_from_url';

import { DefaultQuestionsForm, OptInFormFieldValues } from './default_questions_form';
import { Header } from './header';
import { OptInSuccess } from './opt_in_success';
import { Survey } from './survey';

import * as styles from './opt_in.module.css';

interface OptInProps {
  optInFormId: string,
}

// Order of page loading:
// - Load opt in form data
// - If URL does not have P opt in form key data, that's it. page loaded
// - But if there IS opt in form key data, then fetch latest existing response set
// - Show default question form or survey depending on latest response set status
export function OptIn({ optInFormId }: OptInProps) {
  const [surveyComplete, setSurveyComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { dismissMessage, messages, setMessage } = useToast();

  const {
    participantId: participantIdFromUrl,
    participantOptInFormKey: participantOptInFormKeyFromUrl,
  } = useOptInFormKeyFromQueryUrl();

  const location = useLocation();
  const navigate = useNavigate();

  const { data: optInForm } = useGetOptInForm(optInFormId);

  const [getExistingResponseSet, existingResponseSetResult] = useLazyGetResponseSetsQuery();

  const [
    upsertParticipant,
    { data: upsertedParticipant, isLoading: isUpsertingParticipant },
  ] = useUpsertParticipantPopulationOptInFormParticipantMutation();

  // Set the opt in form key data as query params in the URL for access on page refresh
  useEffect(() => {
    if (upsertedParticipant) {
      const { id: participantId, optInFormKey } = upsertedParticipant;
      navigate(`${location.pathname}?participant_id=${participantId}&participant_opt_in_form_key=${optInFormKey}`);
    }
  }, [upsertedParticipant, navigate, location.pathname]);

  useEffect(() => {
    if (!optInForm) return;

    // No URL params so don't bother fetching existing P data
    if (!participantIdFromUrl || !participantOptInFormKeyFromUrl) {
      setIsLoading(false);
      return;
    }

    getExistingResponseSet({
      surveyId: optInForm.surveyId,
      participantId: participantIdFromUrl,
      limit: 1,
      meta: {
        optInFormId: optInForm.id,
        participantId: participantIdFromUrl,
        participantOptInFormKey: participantOptInFormKeyFromUrl,
      },
    }).finally(() => setIsLoading(false));
  }, [optInForm, getExistingResponseSet, participantIdFromUrl, participantOptInFormKeyFromUrl]);

  const handleSubmissionError = (error: Error) => {
    setMessage({
      type: MessageTypes.ERROR,
      message: ErrorRenderer.compileRtkQueryErrorMessage(error, 'submitting your responses'),
    });
  }

  const onSubmitDefaultQuestionsForm = (formValues: OptInFormFieldValues) => {
    upsertParticipant({
      opt_in_form_id: optInForm.id,
      accept_data_consent_notice: formValues.acceptDataConsentNotice,
      accept_texts: formValues.acceptTexts,
      cloudflare_turnstile: formValues.cloudflareTurnstile,
      email: formValues.email,
      ...(formValues.firstName && { first_name: formValues.firstName }),
      ...(formValues.lastName && { last_name: formValues.lastName }),
      ...(formValues.phone?.phoneCountryCode && { phone_country_code: formValues.phone.phoneCountryCode }),
      ...(formValues.phone?.phoneNumber && { phone_number: formValues.phone.phoneNumber }),
      ...(formValues.timezone && { timezone_name: formValues.timezone }),
    }).unwrap()
      .catch(handleSubmissionError);
  }

  // TODO replace with own loading skeleton
  if (isLoading) return <ProjectApplicationLoadingSkeleton />

  const submittedDefaultQuestionsForm = !!upsertedParticipant;
  const urlOptInFormKeyValid = existingResponseSetResult.isSuccess;

  const optInFormComplete = surveyComplete || (submittedDefaultQuestionsForm && !optInForm.surveyId);

  const showSurvey = submittedDefaultQuestionsForm || urlOptInFormKeyValid;

  const participantId = upsertedParticipant?.id || (urlOptInFormKeyValid && participantIdFromUrl);
  const optInFormKey = upsertedParticipant?.optInFormKey || (urlOptInFormKeyValid && participantOptInFormKeyFromUrl);

  return (
    <div className={styles.optInFormPage}>
      <Toast
        autoDismiss
        messages={messages}
        onToastClosed={dismissMessage}
      />
      <Flex
        alignItems="center"
        container
        flexDirection="column"
      >
        <CardStack size="sm">
          <Header
            logoOnly={optInFormComplete}
            logoUrl={optInForm.logoUrl}
            optInFormDescription={optInForm.description}
            optInFormTitle={optInForm.title}
            teamName={optInForm.team.name}
          />

          {optInFormComplete ? (
            <OptInSuccess />
          ) : (
            (showSurvey ? (
              <Survey
                optInFormId={optInForm.id}
                participantId={participantId as string}
                participantOptInFormKey={optInFormKey as string}
                setToastMessage={setMessage}
                surveyId={optInForm.surveyId}
                onComplete={() => setSurveyComplete(true)}
              />
            ) : (
              <DefaultQuestionsForm
                isSubmitting={isUpsertingParticipant}
                optInForm={optInForm}
                onSubmitForm={onSubmitDefaultQuestionsForm}
              />
            ))
          )}
        </CardStack>
      </Flex>
    </div>
  );
}
