import * as propTypes from 'lib/prop_types';

export const questionPropType = propTypes.shape({
  text: propTypes.string.isRequired,
  uuid: propTypes.string.isRequired,
});

export const answerPropType = propTypes.shape({
  text: propTypes.string,
  uuid: propTypes.string.isRequired,
});

export const skipLogicConditionPropType = propTypes.shape({
  answerId: propTypes.string,
  operator: propTypes.string,
  questionId: propTypes.string,
  uuid: propTypes.string,
});

export const skipLogicPropType = propTypes.shape({
  joinMethod: propTypes.string,
  skipLogicConditions: propTypes.arrayOf(skipLogicConditionPropType),
  targetSectionId: propTypes.string,
  uuid: propTypes.string,
});

export const surveySectionPropType = propTypes.shape({
  title: propTypes.string,
  uuid: propTypes.string,
});
