import React, { useContext, useCallback } from 'react';
import propTypes from 'prop-types';

import { faCodeBranch } from 'lib/font_awesome/solid';

import { TrackedButton } from 'common/analytics';
import { AuthUserContext } from 'common/authorization';

import { trackingEvents } from 'lib/analytics';

import SurveyContext from '../../context';

import * as styles from './section_controls.module.scss';

export function SectionControls({ section, hasSkipLogic }) {
  const { account } = useContext(AuthUserContext);

  const {
    setSurveyDuplicateBeforeEdit,
    questionFunctions,
    skipLogicFunctions,
    isReadonly,
  } = useContext(SurveyContext);
  const handleAddQuestion = useCallback(
    () => questionFunctions.add(section.uuid),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [questionFunctions.add, section.uuid],
  );
  const handleEditSkipLogic = useCallback(
    () => {
      setSurveyDuplicateBeforeEdit();

      if (!hasSkipLogic) {
        skipLogicFunctions.add(section.uuid);
      }
      skipLogicFunctions.edit(section.uuid);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      hasSkipLogic,
      skipLogicFunctions.edit,
      skipLogicFunctions.add,
      section.uuid,
      setSurveyDuplicateBeforeEdit,
    ],
  );

  const skipLogicCTA = `${hasSkipLogic ? 'Edit' : 'Add'} skip logic`;

  return (
    <div className={styles.SectionControls}>
      {!isReadonly && (
        <TrackedButton
          event={trackingEvents.SURVEY_ADD_NEW_QUESTION}
          eventData={{
            account_id: account?.id,
          }}
          variant="primary"
          onClick={handleAddQuestion}
        >
          Add new question
        </TrackedButton>
      )}

      {(!isReadonly || hasSkipLogic) && (
        <TrackedButton
          event={hasSkipLogic ? trackingEvents.SURVEY_EDIT_SKIP_LOGIC : trackingEvents.SURVEY_ADD_SKIP_LOGIC}
          eventData={{
            account_id: account?.id,
          }}
          leadingIcon={faCodeBranch}
          variant="link"
          onClick={handleEditSkipLogic}
        >
          {isReadonly ? 'View skip logic' : skipLogicCTA}
        </TrackedButton>
      )}
    </div>
  );
}

SectionControls.propTypes = {
  hasSkipLogic: propTypes.bool.isRequired,
  section: propTypes.shape({
    skipLogicIds: propTypes.array.isRequired,
    uuid: propTypes.string.isRequired,
  }).isRequired,
};
