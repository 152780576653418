import React from 'react';

import { BUTTON_GROUP_ORIENTATIONS, CheckboxButtonGroup } from '@user-interviews/ui-design-system';
import { Controller, useFormContext, useFormState } from 'react-hook-form';

import * as Constants from '../../constants';
import { surveyQuestionPropType } from '../../prop_types';
import { PickManyAnswerInput } from '../answer_input_components/pick_many_answer_input';

import * as styles from './grid_question.module.scss';

export function PickManyInputRow({ question }) {
  const { control } = useFormContext();
  const { errors } = useFormState();

  const validate = value => {
    if (question.isMandatory && value.answerIds.length === 0) {
      return Constants.REQUIRED_ERROR_MESSAGE;
    }
    return null;
  };

  const inputKey = `q${question.id}`;
  const hasError = !!errors[Constants.PICK_MANY_INPUT_GROUP_KEY]?.[inputKey];

  return (
    <Controller
      control={control}
      defaultValue={{ answerIds: [] }}
      name={`${Constants.PICK_MANY_INPUT_GROUP_KEY}.q${question.id}`}
      render={({ field: { onBlur, onChange, value } }) => (
        <CheckboxButtonGroup
          orientation={BUTTON_GROUP_ORIENTATIONS.ROW}
          value={value.answerIds}
          onChange={(answerIds) => onChange({ ...value, answerIds })}
        >
          {question.answers.map(answer => (
            <PickManyAnswerInput
              answer={answer}
              ariaLabel={`${question.text}-${answer.text}`}
              bordered={false}
              className={styles.InputCell}
              formValue={value}
              hasError={hasError}
              key={answer.id}
              question={question}
              value={answer.id}
              onBlur={onBlur}
              onFormChange={onChange}
            />
          ))}
        </CheckboxButtonGroup>
      )}
      rules={{ validate }}
    />
  );
}

PickManyInputRow.propTypes = {
  question: surveyQuestionPropType.isRequired,
};
