import React from 'react';
import propTypes from 'prop-types';

import { Button, CardSizes } from '@user-interviews/ui-design-system';

import { EmptySurveyNotice } from './empty_survey_notice';
import { surveySectionPropType } from '../prop_types';
import { SurveySectionFormCard } from './survey_section_form_card';

import * as styles from './survey_section_form.module.scss';

export function SurveySectionForm({
  cardSize,
  isLoading,
  isSubmitting,
  surveySection,
  onSubmit,
}) {
  const surveyIsEmpty = !(isLoading || isSubmitting || surveySection);

  if (surveyIsEmpty) return <EmptySurveyNotice isPreview />;

  return (
    <>
      <SurveySectionFormCard
        cardSize={cardSize}
        isLoading={isLoading}
        surveySection={surveySection}
      />
      {!isLoading && (
        <div>
          <Button
            className={styles.SubmitButton}
            disabled={isSubmitting}
            isLoading={isSubmitting}
            loadingText="Submitting"
            onClick={onSubmit}
          >
            Continue
          </Button>
        </div>
      )}
    </>
  );
}

SurveySectionForm.propTypes = {
  cardSize: propTypes.oneOf(Object.values(CardSizes)).isRequired,
  isLoading: propTypes.bool.isRequired,
  isSubmitting: propTypes.bool.isRequired,
  surveySection: surveySectionPropType,
  onSubmit: propTypes.func.isRequired,
};

SurveySectionForm.defaultProps = {
  surveySection: null,
};
