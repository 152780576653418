import { registerComponent } from 'lib/react_on_rails';
import SurveyBuilder from './survey_builder';
import * as SurveyBuilderConstants from './constants';
import SurveyContext from './context';

registerComponent('SurveyBuilder', SurveyBuilder);

export {
  SurveyBuilder,
  SurveyBuilderConstants,
  SurveyContext as SurveyBuilderContext,
};
