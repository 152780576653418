import React from 'react';
import propTypes from 'prop-types';

import { SurveyBuilderConstants as Constants } from 'components/survey_builder';

import { surveyQuestionPropType } from '../../prop_types';

import { BooleanAnswerInputGroup } from './boolean_answer_input_group';
import { PickNoneInput } from './pick_none_input';
import { PickOneAnswerInputGroup } from './pick_one_answer_input_group';
import { PickManyAnswerInputGroup } from './pick_many_answer_input_group';

export function SurveyAnswerInput({
  hasError,
  question,
}) {
  const questionType = question.pick;

  switch (questionType) {
    // Checkboxes (multi select)
    case Constants.QUESTION_PICK_ANY: {
      return (
        <PickManyAnswerInputGroup
          hasError={hasError}
          question={question}
        />
      );
    }
    case Constants.QUESTION_PICK_NONE: {
      return (
        <PickNoneInput
          hasError={hasError}
          question={question}
          responseClass={question.answers[0].responseClass}
        />
      )
    }
    // Multiple choice (single select)
    case Constants.QUESTION_PICK_ONE: {
      return (
        <PickOneAnswerInputGroup
          hasError={hasError}
          question={question}
        />
      );
    }
    case Constants.QUESTION_BOOLEAN: {
      return (
        <BooleanAnswerInputGroup
          hasError={hasError}
          question={question}
        />
      );
    }
    default: return null;
  }
}

SurveyAnswerInput.propTypes = {
  hasError: propTypes.bool.isRequired,
  question: surveyQuestionPropType.isRequired,
};
