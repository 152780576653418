import React from 'react';

import {
  Card, CardStack, Flex, LoadingSkeleton,
} from '@user-interviews/ui-design-system';

import { SurveySectionLoadingSkeleton } from 'researcher/surveys/components/survey_section_loading_skeleton';

import * as styles from './project_application_loading_skeleton.module.scss';
import * as headerStyles from './header/recruit_header.module.scss';

export const TEST_ID = 'ProjectApplicationLoadingSkeleton';

const TITLE_HEIGHT = 24;
const GREETING_HEIGHT = 18;
const SUB_GREETING_HEIGHT = 14;

const TITLE_WIDTH = 300;
const GREETING_WIDTH = 200;
const SUB_GREETING_WIDTH = 220;

type ProjectApplicationLoadingSkeletonProps = {
  hideHeader?: boolean,
}

export function ProjectApplicationLoadingSkeleton({ hideHeader }: ProjectApplicationLoadingSkeletonProps) {
  return (
    <Flex
      alignItems="center"
      container
      data-testid={TEST_ID}
      flexDirection="column"
    >
      {!hideHeader && (
        <Flex
          alignItems="center"
          className={styles.header}
          container
          flexDirection="column"
        >
          <div className={headerStyles.heading}>
            <LoadingSkeleton
              className={styles.headingText}
              height={TITLE_HEIGHT}
              width={TITLE_WIDTH}
            />
          </div>
          <div className={headerStyles.divider} />
          <LoadingSkeleton
            className={styles.text}
            height={GREETING_HEIGHT}
            width={GREETING_WIDTH}
          />
          <LoadingSkeleton
            className={styles.text}
            height={SUB_GREETING_HEIGHT}
            width={SUB_GREETING_WIDTH}
          />
        </Flex>
      )}

      <CardStack size="sm">
        <Card size="sm">
          <SurveySectionLoadingSkeleton />
        </Card>
      </CardStack>
    </Flex>
  )
}
