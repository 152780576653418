import React, { useContext, useMemo } from 'react';
import propTypes from 'prop-types';

import { SurveyBuilderContext } from 'components/survey_builder';

import * as Constants from '../../constants';

export function Pick({ answer, isEditing, questionType }) {
  const { formType } = useContext(SurveyBuilderContext);

  const isPickOne = questionType === Constants.QUESTION_PICK_ONE;
  const isBoolean = questionType === Constants.QUESTION_BOOLEAN;

  const qualifier = useMemo(() => {
    if (!answer.text) return null;
    if (formType === Constants.OPT_IN_FORM_TYPE) return null;

    if (answer.qualifyLogic === Constants.QUALIFY_LOGIC_REJECT) return <i> - Reject</i>;

    if (answer.qualifyLogic === Constants.QUALIFY_LOGIC_MUST) {
      return <i> - Must Select</i>;
    }

    if (isPickOne) return <i> - Accept</i>;

    return <i> - May Select</i>;
  }, [answer.text, answer.qualifyLogic, isPickOne, formType]);

  return (
    <div
      className="SurveyBuilderAnswer"
      style={isEditing ? { display: 'none' } : null}
    >
      <input
        aria-label="answer selected preview"
        disabled
        key="input"
        type={isPickOne || isBoolean ? 'radio' : 'checkbox'}
      />
      <span key="text">
        {answer.text}
        {qualifier}
      </span>
    </div>
  );
}

Pick.propTypes = {
  answer: propTypes.shape({
    qualifyLogic: propTypes.string.isRequired,
    text: propTypes.string,
  }).isRequired,
  isEditing: propTypes.bool.isRequired,
  questionType: propTypes.string.isRequired,
};
