import React from 'react';
import propTypes from 'prop-types';

import { TEXT_PROPS, Text } from '@user-interviews/ui-design-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCodeBranch } from 'lib/font_awesome/regular';

import {
  skipLogicPropType,
  surveySectionPropType,
} from './prop_types';
import { QuestionSkipLogicSummaryConditions } from './question_skip_logic_summary_conditions';

import * as styles from './question_skip_logic_summary.module.css';

export function QuestionSkipLogicSummary({
  questionId,
  screenerSections,
  skipLogics,
  survey,
}) {
  const sectionTitlesByUuid = screenerSections
    .filter(section => section !== undefined && section.uuid !== undefined)
    .reduce((mappingById, section) => ({
      ...mappingById,
      [section.uuid]: section.title,
    }), {});

  return (
    <div className={`${styles.skipLogicSummary} skip-logic-summary`}>
      <Text
        className={styles.skipLogicSummaryTitle}
        size={TEXT_PROPS.size.sm}
      >
        <Text as="span" size={TEXT_PROPS.size.md}>
          <FontAwesomeIcon
            className="icon-left"
            icon={faCodeBranch}
          />
        </Text>
        Applied skip logic
      </Text>
      {skipLogics.map(skipLogic => (
        <QuestionSkipLogicSummaryConditions
          key={skipLogic.uuid}
          questionId={questionId}
          skipLogicConditions={skipLogic.skipLogicConditions}
          skipLogicJoinMethod={skipLogic.joinMethod}
          survey={survey}
          targetSectionTitle={sectionTitlesByUuid[skipLogic.targetSectionId]}
        />
      ))}
    </div>
  );
}

QuestionSkipLogicSummary.propTypes = {
  questionId: propTypes.string.isRequired,
  screenerSections: propTypes.arrayOf(surveySectionPropType).isRequired,
  skipLogics: propTypes.arrayOf(skipLogicPropType).isRequired,
  survey: propTypes.object.isRequired,
};
