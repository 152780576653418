import React, { useMemo } from 'react';
import propTypes from 'prop-types';

import { Flex } from '@user-interviews/ui-design-system';
import classNames from 'classnames';

import * as Constants from '../../constants';

import * as styles from './accept.module.scss';

export function Accept({
  answer,
  isEditing,
  questionGroupPick,
  onChange,
}) {
  const { qualifyLogic } = answer;
  const isPickOne = questionGroupPick === Constants.QUESTION_PICK_ONE;

  const handleClick = value => {
    onChange(answer.uuid, { qualifyLogic: value });
  };

  const QualifyLogicOptionsLookup = useMemo(() => {
    const options = isPickOne ? Constants.PICK_ONE_QUALIFY_LOGIC_OPTIONS : Constants.QUALIFY_LOGIC_OPTIONS;

    return options.reduce(
      (acc, option) => {
        acc[option.key] = option;

        return acc;
      }, {},
    )
  }, [isPickOne]);

  const currentOption = QualifyLogicOptionsLookup[qualifyLogic];

  function handleQualifyLogicChange(event) {
    event.stopPropagation();

    handleClick(currentOption.nextKey);
  }

  if (!isEditing) {
    return (
      <Flex
        className={styles.Input}
        container
        justifyContent="center"
      >
        <input
          aria-label="Answer selected preview"
          disabled
          key="input"
          type={isPickOne ? 'radio' : 'checkbox'}
        />
      </Flex>
    )
  }

  return (
    <div className={styles.Toggle}>
      <button
        className={classNames(
          styles.Accept,
          {
            [styles.accept]: qualifyLogic === Constants.QUALIFY_LOGIC_MUST,
            [styles.may]: (qualifyLogic === Constants.QUALIFY_LOGIC_MAY && !isPickOne),
            [styles.mayForPickOne]: (qualifyLogic === Constants.QUALIFY_LOGIC_MAY && isPickOne),
            [styles.reject]: qualifyLogic === Constants.QUALIFY_LOGIC_REJECT,
          },
        )}
        disabled={!isEditing}
        type="button"
        onClick={handleQualifyLogicChange}
      >
        {currentOption.label}
      </button>
    </div>
  )
}

Accept.propTypes = {
  answer: propTypes.object.isRequired,
  isEditing: propTypes.bool.isRequired,
  questionGroupPick: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
};
