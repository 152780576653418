import React from 'react';
import propTypes from 'prop-types';

import { TEXT_PROPS, Text } from '@user-interviews/ui-design-system';

import { SurveyBuilderConstants as Constants } from 'components/survey_builder';

import { answerPropType, questionPropType } from './prop_types';

// Disabled when adding max-props rule.
// Future work should stay under 6 props
// eslint-disable-next-line local-rules/max-props
export function QuestionSkipLogicSummaryCondition({
  conditionAnswer,
  conditionQuestion,
  conditionQuestionGroup,
  isLastCondition,
  questionId,
  skipLogicConditionOperator,
  skipLogicJoinMethod,
}) {
  let questionReferenceName;
  if (conditionQuestion === undefined) {
    questionReferenceName = 'QUESTION NOT FOUND';
  } else if (conditionQuestion.uuid === questionId || conditionQuestion.questionGroupId === questionId) {
    questionReferenceName = 'this question';
  } else if (conditionQuestionGroup) {
    questionReferenceName = conditionQuestionGroup.text;
  } else {
    questionReferenceName = conditionQuestion.text;
  }

  const conditionAnswerText = !conditionAnswer ? 'ANSWER NOT FOUND' : conditionAnswer.text;

  const usesUnequalOperator = skipLogicConditionOperator === Constants.CONDITION_OPERATOR_NOT_EQL;

  return (
    <li>
      {conditionQuestion?.questionGroupId ? (
        <Text
          as="span"
          size={TEXT_PROPS.size.sm}
        >
          <strong>
            {conditionQuestion.text}
          </strong>
          &nbsp;-&nbsp;
          <strong>
            {conditionAnswerText}
          </strong>
        </Text>
      ) : (
        <Text
          as="span"
          size={TEXT_PROPS.size.sm}
        >
          <strong>
            {conditionAnswerText}
          </strong>
        </Text>
      )}
      <Text
        as="span"
        size={TEXT_PROPS.size.sm}
      >
        &nbsp;in&nbsp;
        <strong>
          {questionReferenceName}
        </strong>
        &nbsp;is {usesUnequalOperator && 'not'} chosen

        {!isLastCondition && ` ${skipLogicJoinMethod.toUpperCase()}`}
      </Text>
    </li>
  );
}

QuestionSkipLogicSummaryCondition.propTypes = {
  conditionAnswer: answerPropType,
  conditionQuestion: questionPropType,
  conditionQuestionGroup: questionPropType,
  isLastCondition: propTypes.bool.isRequired,
  questionId: propTypes.string.isRequired,
  skipLogicConditionOperator: propTypes.string.isRequired,
  skipLogicJoinMethod: propTypes.string.isRequired,
};

QuestionSkipLogicSummaryCondition.defaultProps = {
  conditionAnswer: undefined,
  conditionQuestion: undefined,
  conditionQuestionGroup: undefined,
};
