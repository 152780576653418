import * as routes from 'lib/routes';

import { apiSlice } from 'api/base_slice';
import { FEATURE_FLAG_TAG } from 'api/tag_types';
import { deserializeJSONApiResponse } from 'api/utils';

export const featureFlagApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getFeatureFlag: builder.query({
      query: ({ actorType, featureName, sendTracking }) => ({
        method: 'GET',
        url: routes.api_feature_flag_path(featureName, { actorType, sendTracking }),
      }),
      transformResponse: deserializeJSONApiResponse,
      providesTags: (_, __, { featureName }) => [{ type: FEATURE_FLAG_TAG, id: featureName }],
    }),
  }),
  overrideExisting: 'throw',
});

export const { useGetFeatureFlagQuery } = featureFlagApi;
