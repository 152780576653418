import React, { useMemo, useCallback } from 'react';
import propTypes from 'prop-types';
import { Button } from '@user-interviews/ui-design-system';

import { SurveyBuilderConstants as Constants } from 'components/survey_builder';

import { surveyPropType } from '../../prop_types';

import { ConditionRow } from './condition_row';
import { Errors } from './errors';

const getSkipLogicOptions = (sectionId, survey) => {
  const section = survey.sections[sectionId];

  const groupedOptions = [];

  const questions = section.questionIds.reduce((acc, questionId) => {
    const question = survey.questions[questionId];

    if (
      question.pick !== Constants.QUESTION_PICK_NONE &&
      question.pick !== Constants.QUESTION_BOOLEAN
    ) acc.push(question);

    return acc;
  }, [])

  questions.forEach(question => {
    if (question.questionIds) {
      const questionGroupQuestions = question.questionIds.map(questionGroupQuestionId => {
        const questionGroupQuestion = survey.questions[questionGroupQuestionId];
        return {
          label: questionGroupQuestion.text,
          value: questionGroupQuestion.uuid,
        }
      })
      groupedOptions.push({
        label: question.text,
        options: questionGroupQuestions,
      });
    } else {
      groupedOptions.push({
        label: question.text || 'Untitled',
        value: question.uuid,
      });
    }
  });

  return groupedOptions;
};

export function SkipLogic({
  isReadonly,
  sectionId,
  skipLogic,
  survey,
  onRemove,
  onUpdate,
}) {
  const skipLogicId = skipLogic.uuid;

  const handleUpdate = useCallback(
    event => onUpdate(skipLogicId, { targetSectionId: event.target.value }),
    [onUpdate, skipLogicId],
  );

  const handleRemove = useCallback(
    () => onRemove(sectionId, skipLogicId),
    [onRemove, sectionId, skipLogicId],
  );

  const targetableSections = useMemo(() => {
    const sectionIds = survey.sectionIds.slice(survey.sectionIds.indexOf(sectionId) + 1);

    return sectionIds.map((id) => {
      const section = survey.sections[id];

      return <option key={section.uuid} value={section.uuid}>{section.title}</option>;
    });
  }, [survey.sectionIds, survey.sections, sectionId]);

  const skipLogicConditions = useMemo(() => (
    skipLogic.conditionIds.map((conditionId, index) => {
      const skipLogicCondition = survey.skipLogicConditions[conditionId];
      const skipLogicOptions = getSkipLogicOptions(sectionId, survey);

      return (
        <ConditionRow
          isReadonly={isReadonly}
          key={skipLogicCondition.uuid}
          position={index}
          skipLogic={skipLogic}
          skipLogicCondition={skipLogicCondition}
          skipLogicOptions={skipLogicOptions}
          survey={survey}
        />
      );
    })
  ), [
    skipLogic,
    sectionId,
    survey,
    isReadonly,
  ]);

  return (
    <div className="form-group SkipLogicModal__skip-logic">
      {skipLogicConditions}

      <div className="SkipLogicModal__skip-logic__row">
        <b className="SkipLogicModal__skip-logic__row__prefix">
          Skip to
        </b>
        <select
          className="form-select SkipLogicModal__skip-logic__row__control"
          disabled={isReadonly}
          value={skipLogic.targetSectionId || ''}
          onChange={handleUpdate}
        >
          <option value="">Finish</option>
          {targetableSections}
        </select>

        {isReadonly || (
          <Button
            variant="outline-danger"
            onClick={handleRemove}
          >
            Remove
          </Button>
        )}
      </div>

      <Errors skipLogic={skipLogic} survey={survey} />
    </div>
  );
}

SkipLogic.propTypes = {
  isReadonly: propTypes.bool.isRequired,
  sectionId: propTypes.string.isRequired,
  skipLogic: propTypes.object.isRequired,
  survey: surveyPropType.isRequired,
  onRemove: propTypes.func.isRequired,
  onUpdate: propTypes.func.isRequired,
};
