import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingSkeleton } from '@user-interviews/ui-design-system';

import * as Keycodes from 'lib/keycodes';
import { useFormContext } from 'react-hook-form';

import { QuestionSkipLogicSummary } from 'components/question_skip_logic_summary';
import {
  answerPropType,
  questionPropType,
  skipLogicPropType,
  surveySectionPropType,
} from 'components/question_skip_logic_summary/prop_types';

import { faGripVertical } from 'lib/font_awesome/solid';
import { isQuestionGroup } from 'lib/surveys/builder/utilities';
import { useGetPopulationAttributesQuery } from 'api/population_attributes';
import * as Constants from '../constants';

import SurveyContext from '../context';

import { Actions } from './actions';
import { Answers } from './answers';
import { Grid } from './grid';
import { Hint } from './hint';
import { MapAttribute } from './map_attribute';
import { QuestionControls } from './question_controls/question_controls';
import { Text } from './text';
import { Type } from './type';

import './question.scss';

// Disabled when adding max-props rule.
// Future work should stay under 6 props
// eslint-disable-next-line local-rules/max-props
export function Question({
  isRemovableQuestion,
  pageTitle,
  position,
  question,
  questionSkipLogics,
  screenerSections,
  sectionAnswers,
  sectionUUID,
  sectionQuestions,
}) {
  const { formState: { errors }, getValues } = useFormContext();

  const {
    isReadonly,
    formType,
    questionFunctions: {
      add: addQuestion,
      determineType: determineQuestionType,
      duplicate: duplicateQuestion,
      remove: removeQuestion,
      update: updateQuestion,
      updateType: updateQuestionType,
    },
    survey,
  } = useContext(SurveyContext);

  const isOptInForm = formType === Constants.OPT_IN_FORM_TYPE

  const { participantPopulationId } = useParams();

  const {
    data: participantPopulationAttributes,
    isLoading,
  } = useGetPopulationAttributesQuery(
    participantPopulationId,
    { skip: !isOptInForm },
  );

  const [isEditing, setEditing] = useState(!!question.isEditing);

  const hasFrontendErrors =
    errors?.question?.[question.uuid] ||
    Object.keys((errors?.answer || {})).some(key => question.answerIds?.includes(key));

  const isIncompletePage =
    getValues()?.formData?.pageStatuses['screener-survey'] === 'incomplete';

  const answers = useMemo(
    () => question.answerIds?.filter(id => !!survey.answers[id])?.map((answerId) => survey.answers[answerId]),
    [question, survey.answers],
  );

  const hasErrors = useMemo(() => {
    if (Object.keys(question.errors).length || hasFrontendErrors) return true;

    return !!answers?.find(answer => !!Object.keys(answer.errors).length);
  }, [answers, hasFrontendErrors, question.errors]);

  const controlsVisible = !isReadonly && isEditing;

  const questionType = determineQuestionType(question);

  const filterAttributesByQuestionType = (attributes, type) => {
    if (!attributes) return [];

    if (type === Constants.QUESTION_TYPE_DATE) {
      return attributes.filter(attribute => attribute.type === 'date');
    }

    if (type === Constants.QUESTION_TYPE_FLOAT) {
      return attributes.filter(attribute => attribute.type === 'decimal');
    }

    if (type === Constants.QUESTION_TYPE_BOOLEAN) {
      return attributes.filter(attribute => attribute.type === 'boolean');
    }
    return attributes.filter(attribute => attribute.type === 'string');
  };

  const filteredAttributes = filterAttributesByQuestionType(
    participantPopulationAttributes,
    questionType,
  );

  const otherAnswer = useMemo(
    () => answers?.find((answer) => answer.responseClass === Constants.RESPONSE_CLASS_OTHER_AND_STRING),
    [answers],
  );

  const handleQuestionSelected = useCallback(() => {
    if (isReadonly) return;
    setEditing(!isEditing);
  }, [isReadonly, isEditing]);

  const handleChange = useCallback((changes) => {
    updateQuestion(question.uuid, changes);
  }, [question.uuid, updateQuestion]);

  const handleChangeType = useCallback((changes) => {
    updateQuestionType(question.uuid, changes.questionType);
  }, [question.uuid, updateQuestionType]);

  const handleEnterPress = useCallback((event) => {
    if (event.keyCode !== Keycodes.ENTER) return;

    handleQuestionSelected();
  }, [handleQuestionSelected]);

  useEffect(() => {
    const questionLength = Object.keys(survey.questions).length;
    if (questionLength === 1 || isIncompletePage) {
      setEditing(true);
    }
  }, [isIncompletePage, survey.questions]);

  const hasSkipLogic = questionSkipLogics.length > 0;

  const questionNumber = position ? position + 1 : 1;

  if (isLoading) return <LoadingSkeleton count={3} />;

  return (
    <Draggable
      draggableId={question.uuid}
      index={position}
      isDragDisabled={isReadonly}
      key={question.uuid}
    >
      {(provided, snapshot) => (
        <div
          className={classNames(
            'SurveyBuilderQuestion',
            {
              editing: isEditing,
              'has-errors': hasErrors,
              dragging: snapshot.isDragging,
            },
          )}
          ref={provided.innerRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...provided.draggableProps}
          id={`question-${question.uuid}`}
        >
          <div
            className="SurveyBuilderQuestion__Content"
            role="button"
            tabIndex="0"
            onClick={handleQuestionSelected}
            onKeyDown={handleEnterPress}
          >
            {isReadonly || (
              <span
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...provided.dragHandleProps}
              >
                <FontAwesomeIcon
                  aria-label={`Drag question ${questionNumber}`}
                  className="SurveyBuilderQuestion__drag-handle"
                  icon={faGripVertical}
                  size="sm"
                />
              </span>
            )}

            <div className="SurveyBuilderRow">
              <div
                className="SurveyBuilderRow__text"
                id="screener_question"
              >
                <Text
                  isEditing={controlsVisible}
                  isMandatory={question.isMandatory}
                  serverErrors={question.errors.text}
                  text={question.text}
                  uuid={question.uuid}
                  onChange={handleChange}
                />
              </div>

              <div className="SurveyBuilderRow__controls">
                <Type
                  isVisible={controlsVisible}
                  type={questionType}
                  onChange={handleChangeType}
                />
              </div>

              <div className="SurveyBuilderRow__actions">
                <Actions
                  isRemovableQuestion={isRemovableQuestion}
                  isVisible={!isReadonly}
                  questionUUID={question.uuid}
                  sectionUUID={sectionUUID}
                  onAdd={addQuestion}
                  onDuplicate={duplicateQuestion}
                  onRemove={removeQuestion}
                />
              </div>
            </div>

            <div className="SurveyBuilderQuestion__answers">
              {isQuestionGroup(question) ? (
                <Grid
                  isEditing={isEditing}
                  questionGroupId={question.uuid}
                />
              ) : (
                <Answers
                  isEditing={isEditing}
                  question={question}
                />
              )}
            </div>

            {isOptInForm && (
              // this div prevents the question from collapsing when you click on the map attribute component
              // role and keydown are needed to satisfy accessibility linter
              <div
                role="button"
                tabIndex={0}
                onClick={e => e.stopPropagation()}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.stopPropagation();
                  }
                }}
              >
                <MapAttribute
                  attributes={filteredAttributes}
                  question={question}
                  questionType={questionType}
                />
                <Hint question={question} />
              </div>
            )}

            {controlsVisible && (
              <div className="row">
                <QuestionControls
                  otherAnswer={otherAnswer}
                  pageTitle={pageTitle}
                  position={position}
                  question={question}
                  onChange={handleChange}
                />
              </div>
            )}

            {hasSkipLogic && (
              <div
                className={classNames(
                  'skip-logic-summary-container',
                  { 'skip-logic-summary-container--editing': isEditing },
                )}
              >
                <QuestionSkipLogicSummary
                  questionId={question.uuid}
                  screenerSections={screenerSections}
                  sectionAnswers={sectionAnswers}
                  sectionQuestions={sectionQuestions}
                  skipLogics={questionSkipLogics}
                  survey={survey}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
}

Question.propTypes = {
  isRemovableQuestion: propTypes.bool.isRequired,
  pageTitle: propTypes.string.isRequired,
  position: propTypes.number.isRequired,
  question: propTypes.shape({
    answerIds: propTypes.array,
    errors: propTypes.shape({
      text: propTypes.string,
    }).isRequired,
    isEditing: propTypes.bool,
    isKey: propTypes.bool.isRequired,
    isMandatory: propTypes.bool.isRequired,
    isNew: propTypes.bool,
    pick: propTypes.string.isRequired,
    text: propTypes.string.isRequired,
    uuid: propTypes.string.isRequired,
  }).isRequired,
  questionSkipLogics: propTypes.arrayOf(skipLogicPropType).isRequired,
  screenerSections: propTypes.arrayOf(surveySectionPropType).isRequired,
  sectionAnswers: propTypes.arrayOf(answerPropType).isRequired,
  sectionQuestions: propTypes.arrayOf(questionPropType).isRequired,
  sectionUUID: propTypes.string.isRequired,
};
