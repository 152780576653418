import React from 'react';
import propTypes from 'prop-types';

import { Heading } from '@user-interviews/ui-design-system';

import * as styles from './recruit_survey_preview_header.module.scss';

export function RecruitSurveyPreviewHeader({ title }) {
  return (
    <div className={styles.Header}>
      <Heading level={1} size="xxxl" textAlign="center">
        {title}
      </Heading>
    </div>
  );
}

RecruitSurveyPreviewHeader.propTypes = {
  title: propTypes.string.isRequired,
};
