import React, { useMemo } from 'react';
import propTypes from 'prop-types';

import { BUTTON_GROUP_ORIENTATIONS, CheckboxButtonGroup } from '@user-interviews/ui-design-system';
import { Controller, useFormContext } from 'react-hook-form';

import * as Constants from '../../constants';

import { surveyQuestionPropType } from '../../prop_types';
import { isOtherAnswer } from '../../utils';
import { PickManyAnswerInput } from './pick_many_answer_input';

import * as styles from './answer_input.module.scss';

export function PickManyAnswerInputGroup({
  hasError,
  question,
}) {
  const { control } = useFormContext();

  const validate = value => {
    if (question.isMandatory && value.answerIds.length === 0) {
      return Constants.REQUIRED_ERROR_MESSAGE;
    }
    if (value.answerIds.includes(value.otherAnswerId) && value.responseOther.trim() === '') {
      return Constants.REQUIRED_OTHER_VALUE_ERROR_MESSAGE;
    }
    return null;
  };

  const otherAnswerId = useMemo(
    () => question.answers.find(isOtherAnswer)?.id,
    [question.answers],
  );

  return (
    <Controller
      control={control}
      defaultValue={{ answerIds: [], otherAnswerId, responseOther: '' }}
      name={`${Constants.PICK_MANY_INPUT_GROUP_KEY}.q${question.id}`}
      render={({ field: { onBlur, onChange, value } }) => (
        <CheckboxButtonGroup
          fullWidth
          orientation={BUTTON_GROUP_ORIENTATIONS.COLUMN}
          value={value.answerIds}
          onChange={(answerIds) => onChange({ ...value, answerIds })}
        >
          {question.answers.map(answer => (
            <PickManyAnswerInput
              answer={answer}
              className={styles.PickManyAnswer}
              formValue={value}
              hasError={hasError}
              isOtherAnswer={isOtherAnswer(answer)}
              key={answer.id}
              question={question}
              text={answer.text}
              value={answer.id}
              onBlur={onBlur}
              onFormChange={onChange}
            />
          ))}
        </CheckboxButtonGroup>
      )}
      rules={{ validate }}
    />
  );
}

PickManyAnswerInputGroup.propTypes = {
  hasError: propTypes.bool,
  question: surveyQuestionPropType.isRequired,
};

PickManyAnswerInputGroup.defaultProps = {
  hasError: undefined,
};
