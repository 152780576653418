import React from 'react';
import propTypes from 'prop-types';
import { useTracking } from 'react-tracking';

import { IconButton } from '@user-interviews/ui-design-system';

import * as styles from './remove_button.module.scss';

export function RemoveButton({
  ariaLabel,
  className,
  trackingEvent,
  onRemove,
}) {
  const { trackEvent } = useTracking();

  const handleClick = event => {
    event.stopPropagation();

    trackEvent({ event: trackingEvent });

    onRemove();
  };

  return (
    <IconButton
      action="DELETE"
      ariaLabel={ariaLabel}
      className={`${styles.RemoveButton} ${className}`}
      size="sm"
      onClick={handleClick}
    />
  );
}

RemoveButton.propTypes = {
  ariaLabel: propTypes.string.isRequired,
  className: propTypes.string,
  trackingEvent: propTypes.string.isRequired,
  onRemove: propTypes.func.isRequired,
};

RemoveButton.defaultProps = {
  className: undefined,
};
