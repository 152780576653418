import React from 'react';

import { LoadingSkeleton } from '@user-interviews/ui-design-system';

import * as styles from './survey_section_loading_skeleton.module.scss';

export const TEST_ID = 'SurveySectionLoadingSkeleton';

const QUESTION_TITLE_HEIGHT = 14;
const QUESTION_TITLE_WIDTH = '40%';
const QUESTION_ANSWER_HEIGHT = 54;
const QUESTION_ANSWER_WIDTH = '100%';

const TOTAL_QUESTION_GROUPS = 2;

export function SurveySectionLoadingSkeleton() {
  return (
    <div data-testid={TEST_ID}>
      {[...Array(TOTAL_QUESTION_GROUPS).keys()].map(key => (
        <div
          className={styles.QuestionGroup}
          key={key}
        >
          <LoadingSkeleton
            className={styles.QuestionTitle}
            height={QUESTION_TITLE_HEIGHT}
            width={QUESTION_TITLE_WIDTH}
          />
          <LoadingSkeleton
            className={styles.Answer}
            count={2}
            height={QUESTION_ANSWER_HEIGHT}
            width={QUESTION_ANSWER_WIDTH}
          />
        </div>
      ))}
    </div>
  )
}
