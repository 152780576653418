import React, { Component } from 'react';

import { track, trackingPropsShape } from 'lib/analytics';
import * as propTypes from 'lib/prop_types';

import { getDisplayName } from './utils';

export default function withTrackedClick(Target) {
  class TrackedComponent extends Component {
    static displayName = `${getDisplayName(Target)}WithTrackedClick`;

    static propTypes = {
      event: propTypes.string.isRequired,
      eventData: propTypes.object,
      onClick: propTypes.func,
      ...trackingPropsShape,
    };

    static defaultProps = {
      eventData: {},
      onClick: undefined,
    };

    handleClick = (event) => {
      this.props.tracking.trackEvent({
        ...this.props.eventData,
        event: this.props.event,
      });

      if (this.props.onClick) {
        this.props.onClick(event);
      }
    };

    get targetProps() {
      const transferProps = { ...this.props };

      // Need to remove eventData, otherwise child props complain about:
      // https://reactjs.org/warnings/unknown-prop.html
      delete transferProps.eventData;

      return {
        ...transferProps,
        onClick: this.handleClick,
      };
    }

    render() {
      return <Target {...this.targetProps} />;
    }
  }

  return track()(TrackedComponent);
}
