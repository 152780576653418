import React from 'react';
import { Provider } from 'react-redux';
import { z } from 'zod';

import { useZodSchema } from 'hooks/use_zod_schema';
import { useRailsContext } from 'hooks/use_rails_context';
import { DopeBrowserRouter } from 'components/dope_browser_router';
import store from 'store/store';

import { OptIn } from './components/opt_in';

const optInPageSchema = z.object({
  optInFormId: z.string(),
});

export function OptInPage(props: unknown) {
  const { optInFormId } = useZodSchema(props, optInPageSchema);
  const railsContext = useRailsContext();

  return (
    <DopeBrowserRouter location={railsContext?.request.location ?? '/'}>
      <Provider store={store}>
        <OptIn optInFormId={optInFormId} />
      </Provider>
    </DopeBrowserRouter>
  );
}
