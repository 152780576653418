import React from 'react';
import propTypes from 'prop-types';

import { faCircleCheck, faSync } from 'lib/font_awesome/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Flex,
  Heading,
  Text,
} from '@user-interviews/ui-design-system';

import * as styles from './survey_preview_end.module.scss';

export function SurveyPreviewEnd({ onRestartPreview, isOptInForm }) {
  return (
    <Flex
      alignItems="center"
      container
      flexDirection="column"
    >
      <div className={styles.SuccessIcon}>
        <FontAwesomeIcon
          data-testid="end-of-preview-check-icon"
          icon={faCircleCheck}
        />
      </div>

      <Heading
        level={2}
        size="xxxl"
        textAlign="center"
        weight="bold"
      >
        { `You've reached the end of the ${isOptInForm ? 'opt-in form' : 'screener survey'} preview.`}
      </Heading>

      <Text
        size="lg"
        textAlign="center"
      >
        {!isOptInForm && 'If everything looks good, close this window and continue setting up your project. '}
        To start over or test another skip logic path, click the button below.
      </Text>

      <Button
        className={styles.RestartButton}
        onClick={onRestartPreview}
      >
        <FontAwesomeIcon
          className="icon-left"
          icon={faSync}
        />
        Go back to start
      </Button>
    </Flex>
  )
}

SurveyPreviewEnd.propTypes = {
  isOptInForm: propTypes.bool,
  onRestartPreview: propTypes.func.isRequired,
};

SurveyPreviewEnd.defaultProps = {
  isOptInForm: false,
}
